import React from 'react'
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import styles from "../style/about.module.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({data}) => {

    const imageSrc = [
        data.visualStimProjFluid.childImageSharp.fluid,
        data.dataAnalysisProjFluid.childImageSharp.fluid,
    ]

    return(
    <Layout>
        <SEO title='Projects & More Info' description='More info and Projects by Paul Jang'/> 
            <h1>More Info & Projects</h1>
            <hr className={styles.titleDivider}/>
            <div className={styles.moreInfo}>
                <p>I am of Korean heritage and was born and raised on the East Coast of the United States.</p>

                <p>My research background focused on signal processing and bioelectric signals, but my passion lies in developing clean, well documented code that solves interesting problems.</p>

                <p>I've worked the most with scripting languages (<strong>MATLAB</strong> and <strong>python</strong>), but I am always looking to learn and familiarize myself with all the various languages out there - I'm not afraid to teach myself new languages and concepts.</p>  

            </div>
        <div className={styles.aboutMain}>

            <h2>Some Projects</h2>
            <hr className={styles.projDiv}/>
            <div className={styles.projectTitle}>
                <h3>Vision Research Stimuli Suite</h3>
                <h4>Matlab/Psychtoolbox backend, Java + Psychtoolbox frontend</h4>
            </div>
            <div className={styles.nestedImages}>

                <Img className={styles.projFluidImages} fluid={imageSrc[0]} alt="Example of Vision System User Interface"/>

            </div>

            <div className={styles.projectText}>
                <p>A custom vision research system, it is primarily tasked with generating & delivering visual patterns at high refresh rates.</p>
                <p>The system uses two screens; the experimental screen to deliver stimuli and a user screen which has ui to control and adjust the stimuli.</p>
                <p>
                    During experimental routines, the system has additional duties alongside accurate visual delivery. 
                    The system communicates with a data acquisition system to reliably mark key trial points and process data for online feedback.
                    The system is also responsible for controlling other subsystems, which it performs using analog and digital signals.
                </p>
            </div>

            <hr className={styles.projDiv}/>

            <div className={styles.projectTitle}>
                <h3>This Website!</h3>
                <h4>HTML, CSS, Javascript, React, Gatsby</h4>
            </div>

            <div className={styles.projectText}>

                <p>Built this website from the ground up!</p>
                <p>Taught myself Gatsby, and all the underlying languages and frameworks the language depends on in a short period of time.</p>
                <p>Creating this website was part of my efforts to understand modern web development, and strengthen my capabilities as a full stack developer.</p>
            </div>

            <hr className={styles.projDiv}/>

            <div className={styles.projectTitle}>
                <h3>Data Analysis Toolkit</h3>
                <h4>Matlab and Python, Numpy and Matplotlib</h4>
            </div>
            
            <div className={styles.nestedImages}>
                <Img className={styles.projFluidImages} fluid={imageSrc[1]} alt="Example of processed waveform"/>
            </div>

            <div className={styles.projectText}>
                <p>Developed a set of scripts to process electrophysiology data.</p>
                <p>
                    Majority of time was spent parsing raw data files into annotated traces and implementing robust signal processing algorithms to process varying quality waveforms.
                </p>
                <p>
                    Written in both MATLAB and python to make it more accessible (both in terms of cost and language familitarity).
                </p>
            </div>
        </div>
    </Layout>
    )

}
 

export const projQuery  = graphql`
    query imgQuery {
        visualStimProjFluid: file(relativePath: { eq: "visual-stim.png" }) {
            childImageSharp {
                fluid(maxHeight: 576, quality:100, fit: CONTAIN) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        dataAnalysisProjFluid: file(relativePath: { eq: "data-analysis.png" }) {
            childImageSharp {
                fluid(maxHeight: 576, quality:100, fit: CONTAIN) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

    }
    `

export default AboutPage;
